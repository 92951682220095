// views
import { Alert } from "antd";
import Link from "next/link";

// utils
import _ from "lodash";

export default (props) => {
  if (!props.user) {
    return null;
  }

  const { post_deleted, post_comment_deleted, post_pinned } =
    props.messageCount.kind;

  if (
    (post_deleted && post_deleted > 0) ||
    (post_comment_deleted && post_comment_deleted > 0) ||
    (post_pinned && post_pinned > 0)
  ) {
    let messageText = "";
    if (post_deleted > 0) {
      messageText = "鸭！您有一个帖子被社区管理员删除啦。";
    } else if (post_comment_deleted > 0) {
      messageText = "鸭！您有一条评论被社区管理员删除啦。";
    } else if (post_pinned > 0) {
      messageText = "您的一篇帖子已被管理员置顶膜拜";
    }

    const message = (
      <div>
        <span>{messageText}</span>
        <Link legacyBehavior href={`/messages?category=system`}>
          <a className="text-primary px-5">去看看➔</a>
        </Link>
      </div>
    );

    return <Alert type="warning" message={message} className="mt-20" />;
  }
  return null;
};
