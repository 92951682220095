import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// TODO: Migrate fetchFeatures to services.
import { fetchFeatures } from "~/redux/actions/app";

const FeaturesContext = createContext();
export const useFeatures = () => useContext(FeaturesContext);

export const FeaturesProvider = ({ children }) => {
  // TODO: Migrate to AuthContext
  const token = useSelector((state) => state.user.token);
  const [features, setFeatures] = useState({});

  useEffect(() => {
    if (token) {
      fetchFeatures(token).then(setFeatures);
    } else {
      setFeatures({});
    }
  }, [token]);


  // 将 features 数据提供给子组件
  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
};