// react
import { useState } from 'react'

// views
import { Button, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

export default props => {
  // hooks
  const [state, setState] = useState({ visible: false })

  // handles
  const handleShowDrawer = () => setState({ visible: true })
  const handleHideDrawer = () => setState({ visible: false })

  return (
    <div>
      <div className='drawer-trigger'>
        <Button
          type='primary'
          icon={<MenuOutlined />}
          onClick={handleShowDrawer}
        />
      </div>

      <div className='drawer-wrapper'>
        <Drawer
          title='电鸭社区'
          placement='right'
          closable
          onClose={handleHideDrawer}
          visible={state.visible}
         >
          {props.children}
        </Drawer>
      </div>

      <style jsx>{`
        div {
          height: 100%;
        }

        .drawer-trigger {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      `}
      </style>
    </div>
  )
}
