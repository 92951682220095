import { useState } from "react";
import Link from "next/link";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { signout } from "~/redux/actions/user";
import { getMessageCount } from "~/redux/actions/message";
import { showAppModal } from "~/redux/actions/app";

import { Button, Avatar, Badge } from "antd";
import UserBadge from "../user/UserBadge";

import { avatarUrl } from "~/utils/app";
import { getUserBadge } from "../../utils/ui";
import { isWechatBrowser } from "~/utils/wechat";
import { hasContactInfo } from "~/utils/user";
import settings from "../../settings";

const AppAvatarDropdownMenu = (props) => {
  const { user, signout, messageCount } = props;
  const [visible, setVisible] = useState(false);

  const handleSignin = () => {
    if (isWechatBrowser()) {
      props.showAppModal("WechatSigninModal");
    } else {
      props.showAppModal("PhoneSigninModal");
    }
  };

  const handleSignup = () => {
    props.showAppModal("PhoneSignupModal");
  };

  return (
    <>
      <div className="app-avatar-dropdown-menu-trigger">
        {props.user ? (
          <div onClick={() => setVisible(!visible)} className="pos-relative">
            <Badge dot={messageCount.total > 0 || !hasContactInfo(user)}>
              <Avatar
                src={avatarUrl(props.user, 48)}
                size="30"
                style={
                  getUserBadge({ user: props.user, avatarSize: 30 })
                    .avatarBorderStyle
                }
                shape="circle"
              />
            </Badge>

            <UserBadge
              user={props.user}
              position="AppMenuRight"
              avatarSize="30"
            />
          </div>
        ) : (
          <>
            <Button size="small" className="signin-btn" onClick={handleSignin}>
              登录
            </Button>
            <Button
              size="small"
              type="primary"
              className="signup-btn"
              onClick={handleSignup}>
              注册
            </Button>
          </>
        )}
      </div>
      <div
        className={
          visible
            ? "app-avatar-dropdown-menu-mask show"
            : "app-avatar-dropdown-menu-mask"
        }
        onClick={() => setVisible(false)}>
        <div
          className={
            visible
              ? "app-avatar-dropdown-menu show"
              : "app-avatar-dropdown-menu"
          }>
          <div onClick={(event) => event.stopPropagation()}>
            <div className="app-avatar-dropdown-menu-me">
              <Link legacyBehavior href="/profile/info">
                <a>个人设置</a>
              </Link>
              <Link legacyBehavior href="/messages">
                <a className="flex align-items-center justify-content-center">
                  <span>我的消息</span>
                  <Badge size="small" count={messageCount.total}></Badge>
                </a>
              </Link>
              <Link legacyBehavior href={`${settings.talentUrl}/me/profile`}>
                <a>我的简历</a>
              </Link>
              <Link legacyBehavior href="/applications">
                <a>职位状态</a>
              </Link>
              <div
                onClick={() => {
                  setVisible(false);
                  signout();
                }}>
                退出
              </div>
            </div>
            <div className="app-avatar-dropdown-menu-others">
              <Link legacyBehavior href="/">
                <a>首页</a>
              </Link>
              <Link legacyBehavior href="/posts/6GzfGe">
                <a>社区规则</a>
              </Link>
              <Link legacyBehavior href="/posts/6yGflz">
                <a>社区共建</a>
              </Link>
              <Link legacyBehavior href="/posts/MZXfoe">
                <a>关于电鸭</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user.user,
  messageCount: state.message.messageCount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMessageCount,
      signout,
      showAppModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppAvatarDropdownMenu);
