// resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

import cookie from "js-cookie";
import cookieParser from "cookie";
import { isClient } from "~/utils/app";
import settings from "~/settings";

const domain = process.env.NODE_ENV === "development" ? undefined : settings.domain;

/**
 * 更新 cookie
 * 
 * @param {String} key cookie key 
 * @param {String} value cookie value
 * @param {Object} options cookie 选项
 * @param {Integer} options.expires cookie 过期时间，单位天，默认为7天
 * @param {String} options.path cookie 请求路径，默认为整站 /
 * @param {String} options.domain cookie 域名，默认为当前域名
 */
export const setCookie = (key, value, options = {}) => {
  if (isClient()) {
    const { expires = 7, path = "/", domain = domain } = options;
    cookie.set(key, value, { expires, path, domain });
  }
};

export const removeCookie = (key) => {
  if (isClient()) {
    cookie.remove(key);
    cookie.remove(key, { domain });
  }
};

export const getCookie = (key, req) => {
  return isClient()
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

export const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (req.headers.cookie) {
    const cookies = cookieParser.parse(req.headers.cookie);
    return cookies[key];
  } else {
    return undefined;
  }
};

/**
 * 更新用户 token 到 cookie 中
 * 
 * 这里指定 30 天过期，大于服务端过期时间 4 周
 *
 * @param {String} token 用户 token
 */
export const updateTokenCookie = (token) =>
  setCookie(settings.cookieTokenName, token, { expires: 30 });

/**
 * 从 cookie 中删除用户 token 
 */
export const removeTokenCookie = () =>
  removeCookie(settings.cookieTokenName);