// react
import { useEffect, useState } from "react";

// next
import { useRouter } from "next/router";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getMessageCount } from "~/redux/actions/message";
import { showAppModal, showAppMessage } from "~/redux/actions/app";
import { signout } from "~/redux/actions/user";

// views
import Link from "next/link";
import { Menu, Avatar, Badge, Row, Col, Input, Tooltip, message } from "antd";
import AppSearch from "~/components/app/AppSearch";

// lodash
import _ from "lodash";

// utils
import { avatarUrl, truncate } from "~/utils/app";
import { hasContactInfo } from "~/utils/user";
import { isWechatBrowser } from "~/utils/wechat";
import { hasAnyRole } from "../../utils/user";
import UserBadge from "../user/UserBadge";
import settings from "../../settings";
import { isMobile } from "react-device-detect";
import { getUserBadge } from "../../utils/ui";

const { Search } = Input;

const AppMenuRight = (props) => {
  // router
  const router = useRouter();

  // props
  const { user, token, messageCount } = props;
  const [items, setItems] = useState();

  // handles
  const handleSignin = () => {
    if (isWechatBrowser()) {
      props.showAppModal("WechatSigninModal");
    } else {
      props.showAppModal("PhoneSigninModal");
    }
  };
  const handleSignup = () => {
    props.showAppModal("PhoneSignupModal");
  };
  const handleInit = () => {
    token && props.getMessageCount(token);
  };
  const handleSearch = (key) => {
    const keyword = encodeURIComponent(_.trim(key));
    keyword
      ? router.push("/search?keyword=" + keyword)
      : props.showAppMessage("请输您要搜索的关键字", "info");
  };

  // events
  useEffect(() => handleInit(), []);

  const isAdmin = hasAnyRole(props.user, ["admin"]);
  const isHr = hasAnyRole(props.user, ["hr"]);
  let avatarSize = props.mode === "horizontal" ? 45 : 20;

  const buildBaseItems = () => [
    {
      label: (
        <Badge dot={!hasContactInfo(user)}>
          <Link legacyBehavior href="/profile/info">
            <a className="text-md">个人设置</a>
          </Link>
        </Badge>
      ),
      key: "个人设置",
    },
    {
      label: (
        <a href="/messages">
          <Row type="flex" align="middle">
            <Col span={18} className="text-">
              我的消息
            </Col>
            <Col span={6}>
              <div className="text-right">
                <Badge
                  count={messageCount.total}
                  style={{
                    fontSize: "10px",
                    height: "14px",
                    lineHeight: "14px",
                  }}
                />
              </div>
            </Col>
          </Row>
        </a>
      ),
      key: "我的消息",
    },
    {
      label: (
        <Link legacyBehavior href={`${settings.talentUrl}/me/profile`}>
          <a className="text-md">我的简历</a>
        </Link>
      ),
      key: "我的简历",
    },
    {
      label: (
        <Link legacyBehavior href="/applications">
          <a className="text-md">职位状态查看</a>
        </Link>
      ),
      key: "职位状态查看",
    },
  ];

  // const searchItems = [
  //   {
  //     label: (
  //       <div className="app-menu-search">
  //         <Search
  //           placeholder="请输入关键字"
  //           onSearch={(value) => handleSearch(value)}
  //           style={{ width: 180 }}
  //           enterButton
  //         />
  //       </div>
  //     ),
  //     key: "关键字查询",
  //   },
  // ];
  const searchItems = [
    {
      label: <AppSearch></AppSearch>,
      key: "关键字查询",
    },
  ];

  const loginItems = [
    { label: <span onClick={handleSignin}>登录</span>, key: "登录" },
    { label: <span onClick={handleSignup}>注册</span>, key: "注册" },
  ];

  const signoutItem = {
    label: <a onClick={() => props.signout()}>退出</a>,
    key: "退出",
  };

  const reportItem = {
    label: (
      <Link legacyBehavior href={`/admin/reports`}>
        <a>后台管理-管理员</a>
      </Link>
    ),
    key: "后台管理-管理员",
  };

  const talentHrItem = {
    label: (
      <Link legacyBehavior href={`${settings.talentUrl}/hr`}>
        <a>远程人才库-HR</a>
      </Link>
    ),
    key: "远程人才库-HR",
  };

  const talentAdminItem = {
    label: (
      <Link legacyBehavior href={`${settings.talentUrl}/admin`}>
        <a>远程人才库-管理员</a>
      </Link>
    ),
    key: "远程人才库-管理员",
  };

  const buildItems = (items) => buildBaseItems().concat(items);

  useEffect(() => {
    if (props.user) {
      const headerItem = {
        label: (
          <span style={{ position: "relative" }}>
            <Badge dot={messageCount.total > 0 || !hasContactInfo(user)}>
              <Avatar
                src={avatarUrl(user, 48)}
                size={avatarSize}
                style={getUserBadge({ user, avatarSize }).avatarBorderStyle}
                shape="circle"
              />
            </Badge>
            {avatarSize > 20 && (
              <UserBadge
                user={user}
                position="AppMenuRight"
                avatarSize={avatarSize}
              />
            )}
            <span className="ml-5" title={user.nickname}>
              {truncate(user.nickname, 12)}
            </span>
          </span>
        ),
        children: [],
        key: "headerItem",
      };
      if (isMobile || (!isHr && !isAdmin)) {
        headerItem.children = buildItems([signoutItem]);
      } else {
        if (isHr && isAdmin) {
          headerItem.children = buildItems([
            talentHrItem,
            talentAdminItem,
            reportItem,
            signoutItem,
          ]);
        } else if (isAdmin && !isHr) {
          headerItem.children = buildItems([
            talentAdminItem,
            reportItem,
            signoutItem,
          ]);
        } else if (isHr && !isAdmin) {
          headerItem.children = buildItems([talentHrItem, signoutItem]);
        }
      }
      setItems(isMobile ? [headerItem] : searchItems.concat(headerItem));
    } else {
      setItems(isMobile ? loginItems : searchItems.concat(loginItems));
    }
  }, [props.user, props.messageCount]);

  return (
    <Menu
      items={items}
      mode={props.mode}
      selectable={false}
      className="app-menu-right"
      disabledOverflow={true}
    />
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user.user,
  messageCount: state.message.messageCount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      getMessageCount,
      signout,
      showAppMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppMenuRight);
