import { Provider } from "react-redux";
import App from "next/app";
import Router from "next/router";

import withRedux from "next-redux-wrapper";
import { initStore } from "~/redux";
import { getCookie } from "~/utils/cookie";
import { authRequest, request } from "~/utils/api";
import { ModalProvider } from "react-modal-hook";
import { showAppMessage } from "~/redux/actions/app";
import {
  setUserToken,
  setUserInfo,
  setInviteCode,
  setInviteValid,
} from "~/redux/actions/user";
// import "~/styles/index.css";
import "~/styles/app.less";

import { FeaturesProvider } from "~/context/FeaturesContext";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";

import Error from "~/pages/_error";
//增加浏览器兼容
import "@babel/polyfill";
import settings from "../settings";
import ReactGA from "react-ga4";

if (settings.env !== "development") {
  ReactGA.initialize(settings.ga);
  ReactGA.send("pageview");
}

export default withRedux(initStore, { debug: false })(
  class EleDuckApp extends App {
    componentDidMount() {
      let dom = document.getElementById("holderStyle");
      if (dom) {
        dom.remove();
      }
    }

    static async getInitialProps({ Component, ctx }) {
      let authorized = false;
      const token = getCookie(settings.cookieTokenName, ctx.req);

      const inviteCode = getCookie("inviteCode", ctx.req);
      if (inviteCode) {
        ctx.store.dispatch(setInviteCode(inviteCode));

        try {
          const params = { code: inviteCode };
          const resp = await request({ url: "/auth/invite", params });
          const { verified } = resp.data;
          if (verified) {
            ctx.store.dispatch(setInviteValid(verified));
          } else {
            throw new Error("auth code invalid");
          }
        } catch (e) {
          ctx.store.dispatch(setInviteValid(false));
          ctx.store.dispatch(setInviteCode(null));
        }
      }

      if (token) {
        try {
          const resp = await authRequest(token, { url: "/profile/info" });
          const { user } = resp.data;
          ctx.store.dispatch(setUserToken(token));
          ctx.store.dispatch(setUserInfo(user));
          authorized = true;
        } catch (e) {
          ctx.store.dispatch(setUserToken(null));
          ctx.store.dispatch(setUserInfo(null));
          console.error("auth failed", e);
        }
      }

      if (Component.requireUser && Component.requireUser()) {
        if (!authorized) {
          ctx.store.dispatch(showAppMessage("请先登录", "warning"));

          if (ctx.res) {
            ctx.res.writeHead(302, {
              Location: "/",
            });
            ctx.res.end();
          } else {
            Router.push("/");
          }
        }
      }

      let pageProps = {};
      let err = null;
      if (Component.getInitialProps) {
        try {
          pageProps = await Component.getInitialProps(ctx);
        } catch (err) {
          err = err;
        }
      }

      return { pageProps, err };
    }

    render() {
      const { Component, pageProps, store, err } = this.props;

      if (err) {
        return <Error statusCode={err.statusCode} />;
      }

      return (
        <Provider store={store}>
          <ConfigProvider autoInsertSpaceInButton={false} locale={zhCN}>
            <FeaturesProvider>
              <ModalProvider>
                <Component {...pageProps} />
              </ModalProvider>
            </FeaturesProvider>
          </ConfigProvider>
        </Provider>
      );
    }
  }
);
