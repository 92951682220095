import lscache from "lscache";

import { request, authRequest } from "~/utils/api";
import { postUrl } from "~/utils/route";
import {
  openRequest,
  closeRequest,
  showAppMessage,
  setRedirect,
  fireEvent,
  setPostCheckedTime,
} from "~/redux/actions/app";

export const SAVE_POST_REQUEST = "SAVE_POST_REQUEST";
export const SAVE_DRAFT_REQUEST = "SAVE_DRAFT_REQUEST";
export const SAVE_DRAFT_SUCCESS = "SAVE_DRAFT_SUCCESS";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_DRAFT_SUCCESS = "FETCH_DRAFT_SUCCESS";
export const SET_USER_DRAFT_LIST = "SET_USER_DRAFT_LIST";
export const DELETE_DRAFT_SUCCESS = "DELETE_DRAFT_SUCCESS";

export const createPost = (token, postInfo) => {
  return (dispatch, getState) => {
    dispatch(openRequest(SAVE_POST_REQUEST));
    authRequest(token, {
      url: "/posts",
      method: "POST",
      data: postInfo,
    })
      .then((res) => {
        dispatch(closeRequest(SAVE_POST_REQUEST));
        lscache.remove("draft");
        dispatch(setRedirect(postUrl(res.data.post)));
      })
      .catch((err) => {
        dispatch(closeRequest(SAVE_POST_REQUEST));
        const postCheckedTime = getState().app.postCheckedTime;
        if (err.error === "prohibited_words_failed") {
          if (postCheckedTime === 0) {
            dispatch(showAppMessage(err.message, "error"));
          }
          dispatch(setPostCheckedTime(postCheckedTime + 1));
        } else {
          dispatch(showAppMessage(err.message, "error"));
        }
      });
  };
};

export const updatePost = (token, id, postInfo) => {
  return (dispatch, getState) => {
    dispatch(openRequest(SAVE_POST_REQUEST));
    authRequest(token, {
      url: `/posts/${id}`,
      method: "PATCH",
      data: postInfo,
    })
      .then((res) => {
        dispatch(closeRequest(SAVE_POST_REQUEST));
        lscache.remove("draft");
        dispatch(setRedirect(postUrl(res.data.post)));
      })
      .catch((err) => {
        dispatch(closeRequest(SAVE_POST_REQUEST));
        const postCheckedTime = getState().app.postCheckedTime;
        if (err.error === "prohibited_words_failed") {
          if (postCheckedTime === 0) {
            dispatch(showAppMessage(err.message, "error"));
          }
          dispatch(setPostCheckedTime(postCheckedTime + 1));
        } else {
          dispatch(showAppMessage(err.message, "error"));
        }
      });
  };
};

export const deletePost = (token, id, data = {}) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/posts/${id}`,
      method: "DELETE",
      data: data,
    })
      .then((res) => {
        dispatch(showAppMessage("文章已删除", "success"));
        dispatch(setRedirect("/"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const hidePost = (token, id, hide = true, reason = null, callback) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/posts/${id}/hide${hide ? "" : "?behavior=cancel_hide"}`,
      method: "POST",
      data: { reason },
    })
      .then((res) => {
        dispatch(
          showAppMessage(hide ? "文章已隐藏" : "文章已取消隐藏", "success")
        );
        callback(res.data.hide, res.data.hided_at, res.data.hide_user);
        // dispatch(setRedirect('/'))
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const setVisibility = (
  token,
  id,
  visibility,
  reason = null,
  callback
) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/posts/${id}/visibility`,
      method: "PUT",
      data: { reason, visibility },
    })
      .then((res) => {
        const status = {
          public: "公开",
          private: "私密",
          internal: "内部可见",
          hidden: "隐藏",
        };
        dispatch(
          showAppMessage(`文章可见状态已修改为${status[visibility]}`, "success")
        );
        callback(
          res.data.visibility,
          res.data.hided_at,
          res.data.visibility_setter
        );
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const createDraft = (token, draftInfo, kind = "post") => {
  return (dispatch) => {
    dispatch(openRequest(SAVE_DRAFT_REQUEST));
    authRequest(token, {
      url: "/drafts",
      method: "POST",
      data: draftInfo,
    })
      .then((res) => {
        dispatch(closeRequest(SAVE_DRAFT_REQUEST));
        lscache.remove("draft");
        dispatch(showAppMessage("草稿已保存", "success"));
        dispatch(setRedirect(`/${kind}s/new?draft_id=${res.data.draft.id}`));
        dispatch({ type: SAVE_DRAFT_SUCCESS, draft: res.data.draft });
      })
      .catch((err) => {
        dispatch(closeRequest(SAVE_DRAFT_REQUEST));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const updateDraft = (token, draftId, draftInfo) => {
  return (dispatch) => {
    dispatch(openRequest(SAVE_DRAFT_REQUEST));
    authRequest(token, {
      url: `/drafts/${draftId}`,
      method: "PATCH",
      data: draftInfo,
    })
      .then((res) => {
        dispatch(closeRequest(SAVE_DRAFT_REQUEST));
        lscache.remove("draft");
        dispatch(showAppMessage("草稿已保存", "success"));
        dispatch({ type: SAVE_DRAFT_SUCCESS, draft: res.data.draft });
      })
      .catch((err) => {
        dispatch(closeRequest(SAVE_DRAFT_REQUEST));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const fetchPost = (token, id, params = null) => {
  if (token) {
    return authRequest(token, {
      url: `/posts/${id}`,
      params: params,
    }).then((res) => res.data.post);
  } else {
    return request({
      url: `/posts/${id}`,
    }).then((res) => res.data.post);
  }
};

export const fetchDraft = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/drafts/${id}`,
    })
      .then((res) => {
        dispatch({ type: FETCH_DRAFT_SUCCESS, draft: res.data.draft });
      })
      .catch(() => {
        dispatch(showAppMessage("无效的草稿", "error"));
      });
  };
};

export const deleteDraft = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/drafts/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch({ type: DELETE_DRAFT_SUCCESS, id });
        dispatch(showAppMessage("草稿已删除", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * Post List
 */
export const GET_POST_LIST = "GET_POST_LIST";
export const SET_POST_LIST = "SET_POST_LIST";
export const SET_POST_SUGGESTS = "SET_POST_SUGGESTS";

export const fetchPostList = (params) => {
  if (params.sort === "new") {
    params.sort = "-published_at";
  } else if (params.sort === "hot") {
    params.sort = "hot";
  }

  return request({ url: "/posts", params: params }).then((res) => res.data);
};

export const fetchFollowedPostList = (token, params) => {
  if (params.sort === "new") {
    params.sort = "-published_at";
  } else if (params.sort === "hot") {
    params.sort = "hot";
  }

  return authRequest(token, { url: "/profile/follows/category_posts", params: params }).then((res) => res.data);
};

export const fetchCityPostList = (params) => {
  if (params.sort === "new") {
    params.sort = "-published_at";
  } else if (params.sort === "hot") {
    params.sort = "hot";
  }

  return request({
    url: `/cities/${params.city_id}/posts`,
    params: params,
  }).then((res) => res.data);
};

export const getPostList = (params) => {
  return (dispatch) => {
    dispatch(openRequest(GET_POST_LIST));
    fetchPostList(params)
      .then((data) => {
        dispatch(closeRequest(GET_POST_LIST));
        const { posts, pager } = res.data;
        dispatch({ type: SET_POST_LIST, list: { posts, pager } });
      })
      .catch((err) => dispatch(closeRequest(GET_POST_LIST)));
  };
};

export const getPostSuggests = (params) => {
  return (dispatch) => {
    request({
      url: "/posts/suggests",
      params: params,
    })
      .then((res) => {
        const { posts } = res.data;
        dispatch({ type: SET_POST_SUGGESTS, suggests: posts });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const fetchPostSuggests = (params) => {
  return request({
    url: "/posts/suggests",
    params: params,
  }).then((res) => res.data);
};

export const getUserDraftList = (token, params) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/drafts",
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_USER_DRAFT_LIST, list: res.data });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};

export const SET_USER_POST_LIST = "SET_USER_POST_LIST";
export const getUserPostList = (userId, params) => {
  return (dispatch) => {
    return request({
      url: `/users/${userId}/posts`,
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_USER_POST_LIST, list: res.data });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};

export const fetchUserPostList = (userId, params) => {
  return request({
    url: `/users/${userId}/posts`,
    params: params,
  }).then((res) => res.data);
};

export const SET_PROFILE_POST_LIST = "SET_PROFILE_POST_LIST";
export const getProfilePostList = (token, userId, params) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/profile/posts`,
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_PROFILE_POST_LIST, list: res.data });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};

/**
 * Post Views
 */

export const SET_USER_VIEW_LIST = "SET_USER_VIEW_LIST";
export const getUserViewList = (token, params) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/profile/views",
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_USER_VIEW_LIST, list: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};

/**
 * Post Marks
 */

export const SET_USER_MARK_LIST = "SET_USER_MARK_LIST";
export const CHECK_POST_FLAGS = "CHECK_POST_FLAGS";
export const CREATE_POST_MARK = "CREATE_POST_MARK";
export const DELETE_POST_MARK = "DELETE_POST_MARK";

export const getUserMarkList = (token, params) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/profile/marks",
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_USER_MARK_LIST, list: res.data });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};

export const checkPostFlags = (token, flags, ids) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/posts/check",
      method: "POST",
      data: { flags, ids },
    })
      .then((res) => {
        dispatch({ type: CHECK_POST_FLAGS, flags: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const createPostMark = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/mark`,
      method: "POST",
    })
      .then((res) => {
        dispatch({ type: CREATE_POST_MARK, id });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const deletePostMark = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/mark`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch({ type: DELETE_POST_MARK, id });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const createPostUpvote = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/upvote`,
      method: "POST",
    })
      .then((res) => {
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const deletePostUpvote = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/upvote`,
      method: "DELETE",
    })
      .then((res) => {
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const createPostDownvote = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/downvote`,
      method: "POST",
    })
      .then((res) => {
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const deletePostDownvote = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/downvote`,
      method: "DELETE",
    })
      .then((res) => {
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

/**
 * Post Charges
 */
export const GET_POST_CHARGE_LIST = "GET_POST_CHARGE_LIST";
export const CHARGE_POST_SUBMIT = "CHARGE_POST_SUBMIT";
export const CHARGE_POST_SUCCESS = "CHARGE_POST_SUCCESS";
export const CHARGE_POST_FAILURE = "CHARGE_POST_FAILURE";

export const getPostChargeList = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/charges`,
    })
      .then((res) => {
        dispatch({ type: GET_POST_CHARGE_LIST, ...res.data });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};

export const createPostCharge = (token, id, data) => {
  const { ele, userEle } = data;
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/charges`,
      method: "POST",
      data: { ele },
    })
      .then((res) => {
        let newUserEle = parseInt(userEle) - parseInt(ele);
        dispatch(showAppMessage(`充电成功, 电量剩余${newUserEle}`, "success"));
        dispatch(fireEvent(CHARGE_POST_SUCCESS));
        dispatch(getPostChargeList(token, id));
      })
      .catch((err) => {
        dispatch(fireEvent(CHARGE_POST_FAILURE));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

/**
 * Post Pins
 */
export const createPostPin = (token, id, pinInfo, callback) => {
  if (_.isFunction(pinInfo)) {
    callback = pinInfo;
    pinInfo = {};
  }

  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/pin`,
      method: "POST",
      data: pinInfo || {},
    })
      .then((res) => {
        dispatch(showAppMessage("帖子已置顶", "success"));
        callback(true);
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};
export const deletePostPin = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/pin`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch(showAppMessage("帖子已取消置顶", "success"));
        callback(false);
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

/**
 * Post Contact View
 */
export const checkPostContactView = (token, id) => {
  return authRequest(token, {
    url: `/posts/${id}/contact_view`,
    method: "GET",
  });
};

export const createPostContactView = (token, id) => {
  return authRequest(token, {
    url: `/posts/${id}/contact_view`,
    method: "POST",
  });
};

/**
 * Compare post title
 */

export const SET_COMPARE_POST_LIST = "SET_COMPARE_POST_LIST";
export const GET_COMPARE_POST_LIST = "GET_COMPARE_POST_LIST";
export const SET_COMPARE_LOADING = "SET_COMPARE_LOADING";

export const fetchComparePostList = (token, params) => {
  return (dispatch) => {
    dispatch(openRequest(GET_COMPARE_POST_LIST));
    dispatch({ type: SET_COMPARE_LOADING, loading: true });
    authRequest(token, {
      url: "/posts/similarities",
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch({ type: SET_COMPARE_LOADING, loading: false });
        dispatch({ type: SET_COMPARE_POST_LIST, list: res.data.posts });
        dispatch(closeRequest(GET_COMPARE_POST_LIST));
      })
      .catch((err) => {
        dispatch({ type: SET_COMPARE_LOADING, loading: false });
        dispatch(closeRequest(GET_COMPARE_POST_LIST));
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const restComparePostList = () => {
  return (dispatch) => {
    dispatch({ type: SET_COMPARE_POST_LIST, list: null });
  };
};

/**
 * Post Feature
 */
export const createPostFeature = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/feature`,
      method: "POST",
    })
      .then((res) => {
        dispatch(showAppMessage("帖子已加精", "success"));
        callback(true);
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};
export const deletePostFeature = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/feature`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch(showAppMessage("帖子已取消加精", "success"));
        callback(false);
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const getPostsWithKey = (params) => {
  return request({
    url: "/posts/search",
    params: params,
  }).then((res) => {
    const { posts, pager } = res.data;
    return { posts, pager };
  });
};

export const GET_POST_REPORT_LIST = "GET_POST_REPORT_LIST";
export const getPostReportList = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/posts/${id}/reports`,
    })
      .then((res) => {
        dispatch({ type: GET_POST_REPORT_LIST, reports: res.data.reports });
      })
      .catch(() => {
        dispatch(showAppMessage("加载失败", "error"));
      });
  };
};
