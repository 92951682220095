import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import Link from "next/link";
import { Button } from "antd";
import { Alert } from "antd";
import { showAppModal, setReport } from "~/redux/actions/app";
import SiteLayout from "components/layout/SiteLayout";

const STYLE_OUTER = {
  textAlign: "center",
  fontSize: "24px",
  color: "#4A4A4A",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%,-50%)",
};

const STYLE_MT20 = {
  marginTop: "20px",
};

const Page = (props) => {
  const router = useRouter();
  const query = router.query;
  const { statusCode, error } = props;
  const isRealPost = error && error.code === "post_not_found";

  const page403 = (
    <div>
      <img src="/static/icons/404.gif" alt="403" />
      <p style={STYLE_MT20}>对不起，您没有权限查看此帖子</p>
      <Link legacyBehavior href="/">
        <Button>返回首页</Button>
      </Link>
    </div>
  );

  const page404 = (
    <div>
      <img src="/static/icons/404.gif" alt="404" />
      {isRealPost && (
        <Alert
          style={{ width: "80%", margin: "0 auto", textAlign: "justify" }}
          message="⚠️请谨慎识别和对待qq/tg/猎头高薪/等方式的境外招聘，防止被骗！｜如遇到违法/违规；广告/营销/中介/猎头；过期/虚假/失联/无反馈等性质的帖子，导致你浪费了电量，欢迎点击下方“举报”。管理员核实后，都会退还+额外奖励电量。｜希望大家一起自觉维护社区内容！"
          type="warning"
          showIcon
        />
      )}
      <p style={STYLE_MT20}>
        {error ? error.message : "对不起，什么也没有找到"}
      </p>
      <p className="flex justify-content-center gap-5">
        {statusCode == "404" && isRealPost && (
          <Button
            onClick={() => {
              if (props.token) {
                props.setReport({ contentType: "Post", contentId: query.id });
                props.showAppModal("ReportModal");
              } else {
                props.showAppModal("PhoneSigninModal");
              }
            }}>
            我也要举报
          </Button>
        )}
        <Link legacyBehavior href="/">
          <Button>返回首页</Button>
        </Link>
      </p>
    </div>
  );

  const page500 = (
    <div>
      <img src="/static/error-500.svg" alt="500" />
      <p style={STYLE_MT20}>服务器开小差了，稍后再试吧</p>
    </div>
  );

  const page = {
    404: page404,
    500: page500,
    403: page403,
  };

  return (
    <SiteLayout onlyBody>
      <div style={STYLE_OUTER}>{page[statusCode] || page[500]}</div>
    </SiteLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      setReport,
    },
    dispatch
  );

Page.getInitialProps = async ({ res, err, asPath }) => {
  console.error(`Error in ${asPath}, ${err}`);

  if (res) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { statusCode };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
