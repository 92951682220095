// views
import { Alert } from "antd";
import Link from "next/link";

// utils
import moment from "moment";
import _ from "lodash";
import { formatDate } from "~/utils/app";

export default (props) => {
  if (!props.user) {
    return null;
  }

  const contactInfo = _.trim(props.user.contact_info);
  if (contactInfo) {
    return null;
  }

  const message = (
    <div>
      <span>您还没有填写个人联系方式，其它人将无法联系到您。</span>
      <span className="pl-10">
        <Link legacyBehavior href="/profile/edit">
          <a className="text-primary">立即填写</a>
        </Link>
      </span>
    </div>
  );

  return <Alert type="warning" message={message} className="mt-20" />;
};
